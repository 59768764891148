import { useIsDesktop } from 'contexts/profile';

export function useProductProps(themeName) {
  const isDesktop = useIsDesktop();

  const cards = getCards(themeName, isDesktop);
  const props = getProps(themeName, isDesktop);
  const height = getHeight(themeName, isDesktop);

  return  {
    cards,
    props,
    height,
  }
}

function getCards(themeName, isDesktop) {
  switch(themeName) {
    case 'calathea':
      return isDesktop ? 8 : 6;
    case 'carnation':
    case 'daffodil':
      return 6;
    case 'celosia':
      return 8;
    case 'lilac':
      return isDesktop ? 6 : 5;
    default:
      return isDesktop ? 8 : 6;
  }
}

function getProps(themeName, isDesktop) {
  switch(themeName) {
    case 'lilac':
      return isDesktop ? [{variant:'large'}, {variant:'large'}] : [{variant:'large'}];
  }
}

function getHeight(themeName, isDesktop) {
  switch(themeName) {
    case 'calathea':
    case 'carnation':
      return 600;
    case 'daffodil':
      return isDesktop ? 800 : 600;
    case 'celosia':
      return 476;
    case 'lilac':
      return 700;
    default:
      return 476;
  }
}