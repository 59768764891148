import { defaultProps, props } from 'containers/themes/props/store';
import { TopBar } from 'components/store/TopBar';
import { getUserIcon } from 'components/utils/icon';
import React from 'react';
import NoOrders from 'components/home/NoOrders';
import { useInfiniteShopProducts } from 'hooks/product';
import { useDeviceWidth } from 'hooks/removeJss';
import ViewAll from 'components/store/common/ViewAll';
import LazyLoad from 'react-lazyload';
import { useLanguageLoader } from 'contexts/language';
import {getCatalogs , getProductCard, getTemplates, getShopProfile} from './utils';
import styles from './store.module.css';
import ViewAllCatalogLazy from 'components/store/common/ViewAllCatalogLazy';
import { useProductProps } from './props';
import ShopUtils from 'ui/lib/shopUtils';
import _ from 'ui/lodash';
import DisablePopup from 'components/store/common/DisablePopup';


const emptyProducts = '/images/store/noProducts.svg'

function CustomStore({
  shop,
  savedFeed,
  templates,
  catalogList,
}) {
  const width = useDeviceWidth();
  const [products] = useInfiniteShopProducts(savedFeed);
  const shopIcon = getUserIcon(shop);
  const lang = useLanguageLoader();
  const { uiConfig: { themeConfig } = {} } = shop;

  const Templates = getTemplates[themeConfig?.banners?.name || 'celosia'];
  const ProductCard = getProductCard[themeConfig?.productCard?.name || 'celosia'];
  const Catalogs = getCatalogs[themeConfig?.catalogs?.name || 'celosia'];
  const ShopProfile = getShopProfile[themeConfig?.shopProfile?.name || 'celosia'];
  const props = useProductProps(themeConfig?.productCard?.name || 'celosia');
  const shopProfileEnabled = ShopUtils.isSectionEnabled(shop, 'shopProfile');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const featuredProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');
  const productsEnabled = ShopUtils.isSectionEnabled(shop, 'products');
  const showTemplate = ShopUtils.showBanner(shop);


  const getProps = i => {
    return _.get(props.props, i, {});
  };

  return (
    <div>
      <DisablePopup blocked={shop?.blocked} />
      <TopBar shop={shop} catalogs={catalogList} showTabs={false} />
      <div id="shopScroll1" />
      {shopProfileEnabled && (
        <>
          {themeConfig?.shopProfile?.enabled && <ShopProfile shop={shop} shopIcon={shopIcon} />}
        </>
      )}
      {products.length === 0 && (
        <div className={styles.overflow1}>
          <NoOrders
            title="Shop does not have any products yet!"
            icon={emptyProducts}
          />
        </div>
      )}
      <>
        {showTemplate && <Templates shop={shop} width={width} templates={templates} />}
      </>
      {collectionsEnabled && (
        <>
          {themeConfig?.catalogs?.enabled && <Catalogs shop={shop} catalogs={catalogList} width={width}/>}
        </>
      )}
      {featuredProductsEnabled && (
        <LazyLoad once={true} height={476} offset={100}>
          <ViewAll
            shop={shop}
            products={savedFeed}
            type="featured"
            name={lang('store.headings.featuredProducts')}
            label={`${lang('common.browse')} ${lang('store.headings.featuredProducts')}`}
          >

            {savedFeed.slice(0, props.cards)
              .map((product, idx) => (
                <ProductCard key={product._id} shop={shop} product={product} {...getProps(idx)} />
              ))}
          </ViewAll>
        </LazyLoad>
      )}
      {productsEnabled && (
        <>
          {catalogList.map(catalog => (
            <ViewAllCatalogLazy
              height={props.height}
              key={catalog.value}
              shop={shop}
              name={catalog.value}
              component={ProductCard}
              size={props.cards}
              props={props.props}
            />
          ))}
        </>
      )}
    </div>
  );
}

CustomStore.propTypes = props;
CustomStore.defaultProps = defaultProps;

export default CustomStore;
