import * as celosia from 'components/store/celosia/lazy';
import * as calathea from 'components/store/calathea/lazy';
import * as carnation from 'components/store/carnation/lazy';
import * as lilac from 'components/store/lilac/lazy';
import * as daffodil from 'components/store/daffodil/lazy';


export const getCatalogs = {
  celosia: celosia.Catalogs,
  calathea: calathea.Catalogs,
  carnation: carnation.Catalogs,
  lilac: lilac.Catalogs,
  daffodil: daffodil.Catalogs
};

export const getProductCard = {
  celosia: celosia.ProductCard,
  calathea: calathea.ProductCard,
  carnation: carnation.ProductCard,
  lilac: lilac.ProductCard,
  daffodil: daffodil.ProductCard
};

export const getTemplates = {
  celosia: celosia.Templates,
  calathea: calathea.Templates,
  carnation: carnation.Templates,
  lilac: lilac.Templates,
  daffodil: daffodil.Templates
};

export const getShopProfile = {
  celosia: celosia.ShopProfile,
  calathea: calathea.ShopProfile,
  carnation: carnation.ShopProfile,
  lilac: lilac.ShopProfile,
  daffodil: daffodil.ShopProfile
}
