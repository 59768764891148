import dynamic from 'next/dynamic';

const productCards = {
  celosia: dynamic(() => import('components/store/celosia/ProductCard')),
  carnation: dynamic(() => import('components/store/carnation/ProductCard')),
  calathea: dynamic(() => import('components/store/calathea/ProductCard')),
  lilac: dynamic(() => import('components/store/lilac/ProductCard')),
  daffodil: dynamic(() => import('components/store/daffodil/ProductCard')),
  salvia: dynamic(() => import('components/store/salvia/ProductCard')),
  lupine: dynamic(() => import('components/store/lupine/ProductCard'))
};

export function getProductCard(theme = 'celosia') {
  return productCards[theme.toLowerCase()] || productCards.celosia;
}
